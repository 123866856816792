import React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"
import FrontGallery from "../components/FrontGallery"
import { graphql } from "gatsby"

const Images = ({data, location}) => {
  return (
    <Layout location={location} title="About Magnus">
      <Seo title="Images" />
      <div className="bio">
        <FrontGallery images={data.galleryImages.edges}/>
      </div>
    </Layout>
  )
}
export default Images;
export const imageGalleryQuery = graphql`
  query {
    galleryImages: allMarkdownRemark(
      limit: 1000,
      filter: { frontmatter: { category: { eq: "gallery" } } }
      sort: {
          fields: [frontmatter___date]
          order: DESC
        }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            category
            tags
            date
            photo
            img {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 10, width: 600)
              }
            }
          }
        }
      }
    }
  }
`
