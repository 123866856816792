import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { navigate} from "gatsby"


const FrontGallery = (props) => {
  const imageGallery= {...props};
  return(
    <div className="frontGallery basePad">

      {
        imageGallery.images.map((img,i) => {
          let image = img.node,
          path = image.fields.slug,
          imgData = image.frontmatter.img.childImageSharp.gatsbyImageData,
          imgCaption = `${image.frontmatter.title} - ${image.frontmatter.photo}`
          return (
            <div className={`item item--${i}`} key={i}>
              {/*<GatsbyImage image={img.node.frontmatter.img.childImageSharp.gatsbyImageData} alt=""/>*/}
              <GatsbyImage onClick={(()=> navigate(`${path}`, { state: { modal: true }}))} image={imgData} alt={imgCaption} />
            </div>
          )
        })
      }

    </div>
  )
}
export default FrontGallery;
